import React, { useState } from "react";
import styles from "./../css/index.module.css";
import { useSelector } from 'react-redux';
import theme from "./../../../css/theme.module.css";


const StarRating = (props) => {
    const [hover, setHover] = useState(0);
    const isDay = useSelector(state => state.misc.isDay);

    return (
      <div className={styles["star-rating"]}>
        <div>
        {[...Array(10)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || props.rating) ? styles["on"] : styles["off"]}
              onClick={() => props.setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(props.rating)}
            >
              <span className={styles["star"]}>&#9733;</span>
            </button>
          );
        })}
        </div>
        <span className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{hover > 0 && hover !== props.rating ? hover : props.rating}/10</span>
      </div>
    );
  };

  export default StarRating;