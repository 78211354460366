import React from 'react';
import ReactDOM from 'react-dom';
import Loader from './../img/pulse.gif';
import style from './../css/loading.module.css';
import theme from './../css/theme.module.css';
import { useSelector } from 'react-redux';
import CSSTransition from "react-transition-group/CSSTransition";

const animationTiming = {
    enter: 200,
    exit: 200
};

const LoadingComp = (props) => {

    const isDay = useSelector(state => state.misc.isDay);
    const nodeRef = React.useRef(null);

    return(
        <CSSTransition 
        mountOnEnter 
        unmountOnExit 
        in={props.isLoading} 
        timeout={animationTiming}
        classNames={{
            enter: '',
            enterActive: theme['FadeInModal'],
            exit: '',
            exitActive: theme['FadeOutModal']
        }}
        nodeRef={nodeRef}>
        <>
            <div className={`${style.background} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}/>
            <img src={Loader} className={style.gif} alt='loader' ref={nodeRef}/>
        </>
        </CSSTransition>
    )

}

const LoadingSection = (props) => {

    return(
        <>
        {ReactDOM.createPortal(<LoadingComp isLoading={props.isLoading}/>,document.getElementById("loading"))}
        </>
    )
    
}

export default LoadingSection;