import React from 'react';
import { getBelleRating, returnUrl } from "../util/functions";
import { Link } from 'react-router-dom';
import style from './../css/thumbnail.module.css';
import theme from './../css/theme.module.css';
import { useSelector } from "react-redux";

const Thumbnail = (props) => {
    const isDay = useSelector(state => state.misc.isDay);

    return (
        <Link className={style.thumbnail} to={returnUrl(props.category,props.title)}>
            <div className={style['img-logo']}>
                <img src={props.url} alt={props.title}/>
            </div>
            <div>
                <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.title}</h3>
                <h4 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(props.rating)}</h4>
            </div>
        </Link>
    )
}

export default Thumbnail;