import React,{useState} from 'react';
import './../css/index.css';
import AddRt from './addrt';



const Content = () =>{

    const [formInfo,setFormInfo] = useState({});

    const [endpoint,setEndpoint] = useState("");


    const myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        setFormInfo({...formInfo,[nam]: val});

        switch(val){
            case "0":
                setEndpoint("contentManagement/carousel/");
                break;
            case "1":
                setEndpoint("contentManagement/latestsongs/");
                break;
            case "2":
                setEndpoint("contentManagement/pick/");
                break;
            case "3":
                setEndpoint("contentManagement/latestmovies/");
                break; 
            case "4":
                setEndpoint("contentManagement/throwback/");
                break;  
            case "5":
                setEndpoint("contentManagement/topmovies/");
                break; 
            case "6":
                setEndpoint("contentManagement/topseries/");
                break;   
            case "7":
                setEndpoint("contentManagement/monthmovie/");
                break; 
            case "8":
                setEndpoint("contentManagement/weeksong/");
                break;
            case "9":
                setEndpoint("contentManagement/latestalbums/");
                break; 
            default:
                setEndpoint("");
                break;
        }
       // setEndpoint(val);
    }


    return(
        <>
        <h2>Manage Content</h2>
        <select name="category" id="category" onChange={myChangeHandler}>
            <option value="">Select...</option>
            <option value="0">Home Carousel</option>
            <option value="1">Latest Songs</option>
            <option value="2">Pick of the Day</option>
            <option value="3">Latest Movies</option>
            <option value="4">Throwback Classic</option>
            <option value="5">Top Movies</option>
            <option value="6">Top Series</option>
            <option value="7">Movie of the Month</option>
            <option value="8">Song Of the Week</option>
            <option value="9">Latest Albums</option>
        </select>
           <AddRt endpoint={endpoint}/>
        </>
    );

}

export default Content;