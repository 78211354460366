import React from "react";
import { useSelector } from 'react-redux';
import musicDay from "./../img/music-day.png";
import musicNight from "./../img/music-night.png";
import videoDay from "./../img/video-day.png";
import videoNight from "./../img/video-night.png";
import theme from "./../css/theme.module.css";
import score from "./../css/score.module.css";




const Score = (props) => {
    const isDay = useSelector(state => state.misc.isDay);

    return(
    <div className={score["container"]}>
        <img src={isDay ? videoDay : videoNight} alt="video-score-icon" />
        <span className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.movieScore}</span>
        <img src={isDay ? musicDay : musicNight} alt="music-score-icon" />
        <span className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.musicScore}</span>
    </div>);

}

export default Score;
