import React,{useEffect, useState} from 'react';
import ThumbnailDescription from '../../../components/ThumbnailDescription';
import style from './../css/index.module.css';

const HeaderContent = (props)=>{
    const [index, setIndex] = useState(0);
    const [isLoading,setIsLoading] = useState(false);
   

    useEffect(()=>{
        const interval = setInterval(function(){ 
            setIndex(index => index === 2  ? 0 : index + 1);
        }, 5000);
        let exit = null;
        const entry = setInterval(function(){ 
            setIsLoading(true);
            exit = setTimeout(function(){
                setIsLoading(false);
            },4800)
        }, 5000);
        return () => { clearInterval(interval); clearInterval(entry); clearInterval(exit);}
    },[])

    return(
        <div className={style["header-content"]}>
                <ThumbnailDescription data={props.data} index={index} animate={true} isLoading={isLoading}/>
        </div>
    );

}

export default HeaderContent;