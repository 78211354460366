import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './css/index.module.css';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import theme from './../../css/theme.module.css';
import axios from 'axios';
import { BASE_URL, VERIFY_EMAIL } from '../../endpoint';

const EmailVerify = () => {
    const isDay = useSelector(state => state.misc.isDay);
    let { id } = useParams();
    const [verifyStatus, setVerifyStatus] = useState(0);

    useEffect(() => {
        let verifyEmail = async () => {
            await axios.get(BASE_URL + VERIFY_EMAIL + "?id=" + id)
            .then((response) => {
                setVerifyStatus(response.data.result);
            }).catch((error) =>{
    
            })
        }
        verifyEmail();

    },[id]);

    return(
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>Verify Email | Bad Belle</title>
                <meta name="description" content="This is the verify email page for Bad Belle."></meta>
                <link rel="canonical" href="https://badbelle.ng/verifyEmail" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle Verify Email Page" />
                <meta property="og:description" content="This is the Bad Belle Verify Email page." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/verifyEmail" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle Verify Email Page"/>
                <meta name="twitter:description" content="This is the Bad Belle Verify Email page."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
        <div id="header" className={styles.header} >
            <Header/>
            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Verify Email</h2>
            {verifyStatus === 1 && <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Thank you for verifying your email.</p>}
            {verifyStatus === 2 && <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Your email is already verified.</p>}
        </div>
        <div id="body"> 
        </div>
        <Footer/>
    </div>

    );

}

export default EmailVerify