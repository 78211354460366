import React from 'react';
import SocialMedia from './socialmedia';
import styles from './../css/footer.module.css';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Footer = ()=>{

    return(
        <div className={styles.footer}>
        <div>
            <ul>
                <li><Link to={'/about'}>About Bad Belle</Link></li>
                <li><Link to={'/help'}>Help</Link></li>
                <li><HashLink to={'/about#bellemeter'}>What's the Bellemeter?</HashLink></li>
            </ul>
            <ul>
                {/* <li><Link to={'/team'}>The Team</Link></li> */}
                <li><Link to={'/careers'}>Careers</Link></li>
            </ul>
            <div>
                <h4>Follow us</h4>
                <div className={styles["social-media2"]}>
                    <SocialMedia/>
                </div>
            </div>
        </div>
        <p>Done by <a target="_blank" href="http://www.imgdoesit.com" rel="noreferrer">ImgDoesIt</a></p>
    </div>
    );

}

export default Footer;