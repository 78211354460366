import { createSlice } from '@reduxjs/toolkit';

const miscSlice = createSlice({
    name : 'misc',
    initialState : {
        pickDay : [],
        throwbackClassic : [],
        criticInfo : [],
        carousel : [],
        modalObj :{
            isActive : false,
             type : ""
            },
        isDay : true,
        signInObj : {
            id : localStorage.getItem('id'),
            username : localStorage.getItem('username')
        },
        userScore : {
            musicScore: 0,
            movieScore: 0
        }
    },
    reducers : {
        getPickDay(state,action){
            state.pickDay = action.payload
        },
        getThrowbackClassic(state,action){
            state.throwbackClassic = action.payload
        },
        getCriticInfo(state,action){
            state.criticInfo = action.payload
        },
        clearCriticInfo(state,action){
            state.criticInfo = []
        },
        getCarousel(state,action){
            state.carousel = action.payload
        },
        setHours(state,action){
            state.isDay = action.payload
        },
        toggleModal(state,action){
            state.modalObj = action.payload
        },
        setSignIn(state,action){
            localStorage.setItem('id', action.payload.id);
            localStorage.setItem('username', action.payload.username);
            state.signInObj = action.payload
        },
        setSignOut(state,action){
            localStorage.removeItem('id');
            localStorage.removeItem('username');
            state.signInObj = action.payload
        },
        setUserScore(state,action){
            state.userScore = action.payload
        }
    }
});

export const miscAction = miscSlice.actions;

export default miscSlice;