import { BASE_URL, GET_CAR, GET_CRITIC_INFO, GET_PICK, GET_THROWBACK, GET_USER_SCORE, SIGN_UP, USER_LOGIN } from "../endpoint";
import { miscAction } from "./misc-slice";
//import axios from "axios";

export const getCarouselFunc = () => {
    return async (dispatch) => {
        const getCarousel = async () => {
            const response = await fetch(BASE_URL + GET_CAR, {method: "GET"});
            if(!response.ok){
                throw new Error("Could not get carousel");
            }

            
            const data = await response.json();

            return data;
        }
        try{
            const carousel = await getCarousel();
            dispatch(miscAction.getCarousel(carousel || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const pickDay = () => {
    return async (dispatch) => {
        const getPickDay = async () => {
            const response = await fetch(BASE_URL + GET_PICK, {method: "GET"});
            
            if(!response.ok){
                throw new Error("Could not get pick of the day");
            }

            const data = await response.json();
            
            return data;
        }
        try{
            const pickDay = await getPickDay();
            dispatch(miscAction.getPickDay(pickDay || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const throwbackClassic = () => {
    return async (dispatch) => {
        const getThrowbackClassic = async () => {
            const response = await fetch(BASE_URL + GET_THROWBACK, {method: "GET"});
            
            if(!response.ok){
                throw new Error("Could not get throwback Classic");
            }

            const data = await response.json();
            
            return data;
        }
        try{
            const throwbackClassic = await getThrowbackClassic();
            dispatch(miscAction.getThrowbackClassic(throwbackClassic || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const getCriticInfoFunc = (title) => {
    return async (dispatch) => {
        const getCriticInfo = async () => {
            const response = await fetch(BASE_URL + GET_CRITIC_INFO + "?title=" + title, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get critic info");
            }

            const data = await response.json();

            return data;
        }
        try{
            const criticInfo = await getCriticInfo();
            dispatch(miscAction.getCriticInfo(criticInfo || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const setSignInFunc = (signInObj) => {
    return async (dispatch) => {
        const setSignIn = async () => {
            const response = await fetch(BASE_URL + SIGN_UP, {method: "POST",body: JSON.stringify(signInObj)});

            if(!response.ok){
                throw new Error("Could not sign Up");
            }

            const data = await response.json();

            return data;
        }
        try{
            const signIn = await setSignIn();
            //console.log(signIn);
            dispatch(miscAction.setSignIn(signIn));
        }catch(error){
            console.log(error);
        }
    }
}

export const setLogInFunc = (signInObj) => {
    return async (dispatch) => {
        const setLogIn = async () => {
            const response = await fetch(BASE_URL + USER_LOGIN, {method: "POST",body: JSON.stringify(signInObj)});

            if(!response.ok){
                throw new Error("Could not sign Up");
            }

            const data = await response.json();

            return data;
        }
        try{
            const signIn = await setLogIn();
            //console.log(signIn);
            dispatch(miscAction.setSignIn(signIn));
        }catch(error){
            console.log(error);
        }
    }
}

export const getUserScoreFunc = (userId) => {
    return async (dispatch) => {
        const getUserScore = async () => {
            const response = await fetch(BASE_URL + GET_USER_SCORE + "?userId=" + userId, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get User Score");
            }

            const data = await response.json();

            return data;
        }
        try{
            const userScore = await getUserScore();
            dispatch(miscAction.setUserScore(userScore));
        }catch(error){
            console.log(error);
        }
    }
}

export const signOutFunc = () => {
    return (dispatch) => {
        
        try{
            dispatch(miscAction.setSignOut({id : null, username : null}));
        }catch(error){
            console.log(error);
        }
    }
}

export const clearCriticInfoFunc = () => {
    return (dispatch) => {
        
        try{
            dispatch(miscAction.clearCriticInfo());
        }catch(error){
            console.log(error);
        }
    }
}

export const toggleModal = (type, isActive) => {
    return (dispatch) => {
        let Obj =  {
            isActive : isActive,
             type : type
            }
        try{
            dispatch(miscAction.toggleModal(Obj));
        }catch(error){
            console.log(error);
        }
    }
}

export const setIsDay = () => {
    return  (dispatch) => {
        const getHours =  () => {
            const response =  new Date ();
            let hours = response.getHours();

            return hours < 19 && hours > 6;
        }
        try{
            const hours =  getHours();
            dispatch(miscAction.setHours(hours || 0));
        }catch(error){
            console.log(error);
        }
    }
}