import { createSlice } from '@reduxjs/toolkit';

const musicSlice = createSlice({
    name : 'music',
    initialState : {
        topMusic : [],
        latestMusic : [],
        topAlbums : [],
        latestAlbums : [],
        weekMusic : []
    },
    reducers : {
        getTopMusic(state,action){
            state.topMusic = action.payload
        },
        getLatestMusic(state,action){
            state.latestMusic = action.payload
        },
        getTopAlbums(state,action){
            state.topAlbums = action.payload
        },
        getLatestAlbums(state,action){
            state.latestAlbums = action.payload
        },
        getWeekMusic(state,action){
            state.weekMusic = action.payload
        }
    }
});

export const musicAction = musicSlice.actions;

export default musicSlice;