import { createSlice } from '@reduxjs/toolkit';

const movieSlice = createSlice({
    name : 'movie',
    initialState : {
        topMovies : [],
        latestMovies : [],
        topSeries : [],
        latestSeries : [],
        movieMonth : []
    },
    reducers : {
        getTopMovie(state,action){
            state.topMovies = action.payload
        },
        getLatestMovie(state,action){
            state.latestMovies = action.payload
        },
        getTopSeries(state,action){
            state.topSeries = action.payload
        },
        getLatestSeries(state,action){
            state.latestSeries = action.payload
        },
        getMovieMonth(state,action){
            state.movieMonth = action.payload
        }
    }
});

export const movieAction = movieSlice.actions;

export default movieSlice;