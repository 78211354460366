import React from 'react';
import { Link } from "react-router-dom";
import { getBelleRating, getCat, returnUrl, stringCut } from "../util/functions";
import style from './../css/thumbnailDescription.module.css';
import theme from './../css/theme.module.css';
import { useSelector } from "react-redux";
import CSSTransition from "react-transition-group/CSSTransition";


const animationTiming = {
    enter: 200,
    exit: 200
};

const ThumbnailDescription = (props) => {
    const isDay = useSelector(state => state.misc.isDay);
    if(props.data.length > 0){
    return(<>{props.animate  ?
        (<CSSTransition 
            mountOnEnter 
            unmountOnExit 
            in={props.isLoading} 
            timeout={animationTiming}
            classNames={{
                enter: '',
                enterActive: theme['FadeInCar'],
                exit: '',
                exitActive: theme['FadeOutCar']
            }}
            >
        <Link to={returnUrl(props.data[props.index].category,props.data[props.index].title)} className={style.description}>
                <div>
                    <img src={props.data[props.index].url} alt={props.data[props.index].title} />
                </div>
                <div>
                    <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getCat(props.data[props.index].category)}</label>
                    <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.data[props.index].title}</h3>
                    <h4 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(props.data[props.index].rating)}</h4>
                    <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{stringCut(props.data[props.index].comment,props.data[props.index].category,props.data[props.index].title)}</p>
                </div>
            </Link>
            </CSSTransition>)     
           :
            ( 
            <Link to={returnUrl(props.data[props.index].category,props.data[props.index].title)} className={style.description}>
                <div>
                    <img src={props.data[props.index].url} alt={props.data[props.index].title} />
                </div>
                <div>
                    <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getCat(props.data[props.index].category)}</label>
                    <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.data[props.index].title}</h3>
                    <h4 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(props.data[props.index].rating)}</h4>
                    <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{stringCut(props.data[props.index].comment,props.data[props.index].category,props.data[props.index].title)}</p>
                </div>
            </Link>)
           
           }</>
    );
    }
    else
    {
        return null;
    }
};

export default ThumbnailDescription;