import React,{useState,useRef} from 'react';
import './../css/index.css';
import { BASE_URL, UPLOAD } from '../../../endpoint';


const UploadMedia = () =>{



    const [formInfo,setFormInfo] = useState({title:'',rating:'',files:'',comment:'',category:'',musicType:'',movieType:'',food:'',findIt:''});
    const [isSubmitted,setisSubmitted] = useState(false);
    const ref = useRef();
    const findItRef = useRef();


    

    const getSelectValues =(select) => {
        var result = [];
        var options = select && select.options;
        var opt;
        
        for (var i=0, iLen=options.length; i<iLen; i++) {
            opt = options[i];
        
            if (opt.selected) {
            result.push(opt.value || opt.text);
            }
        }
        return result;
    }

    const submitForm = async (e) => {
        e.preventDefault();
        let url = 'https://api.cloudinary.com/v1_1/jimi/image/upload';
        let picUrls = [];
        
        const files = document.querySelector("[type=file]").files;
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let varObj = null;
            formData.append("file", file);
            formData.append("upload_preset", "badbelleimgs");

            await fetch(url, {
            method: "POST",
            body: formData
            })
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                
                varObj = JSON.parse(data);
                picUrls.push(varObj.secure_url);
            });
        }
        

        let findIt = formInfo.food !== '' ? formInfo.findIt : getSelectValues(document.querySelector("#findIt"));

        const obj = {
            "title" : formInfo.title,
            "rating" : formInfo.rating,
            "category" : formInfo.category,
            "files" : picUrls,
            "comment" : formInfo.comment,
            "musicType": formInfo.musicType,
            "movieType": formInfo.movieType,
            "food":formInfo.food,
            "findIt": findIt,
            "dateCreated" : new Date()
        }

        const jsonObj = JSON.stringify(obj);
        await fetch(BASE_URL + UPLOAD, {
            method: "POST",
            body: jsonObj
            })
            .then((response) => {
                return response.text();
            })
            .then((data) => {
               // setIsUploaded(isImageUploaded);
                setisSubmitted(true);
                setFormInfo((prevState) => {
                return {
                    ...prevState,
                    title : '',
                    rating:'',
                    files:'',
                    comment:'',
                    category:'',
                    musicType:'',
                    movieType:'',
                    food:'',
                    findIt:''}
                });
                ref.current.value = "";
               // findItRef.current.value = "";
            });

    }
    const myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        setFormInfo({...formInfo,[nam]: val});
    }

   

    return(
            <>
           
                <h2>Upload New Entry</h2>
                <form method="post" enctype="multipart/form-data" onSubmit={submitForm}>
                    <input type="text" name="title" id="title" placeholder="What is the title?" className="input-box" onChange={myChangeHandler} value={formInfo.title}/>
                    <label>Choose Category</label>
                    <select name="category" id="category" onChange={myChangeHandler} value={formInfo.category}>
                        <option value="">Select...</option>
                        <option value="0">Music</option>
                        <option value="1">Movies</option>
                        <option value="2">Food</option>
                    </select>
                    {formInfo.category === "0" && 
                    <>
                        <label>Choose Type of Music</label>
                        <select name="musicType" id="musicType" onChange={myChangeHandler} value={formInfo.musicType}>
                            <option value="">Select...</option>
                            <option value="0">Song</option>
                            <option value="1">EP</option>
                            <option value="2">Album</option>
                        </select>
                        <label>Where to find it</label>
                        <select name="findIt" id="findIt" onChange={myChangeHandler} multiple ref={findItRef}>
                            <option value="">Select...</option>
                            <option value="0">Spotify</option>
                            <option value="1">Apple Music</option>
                            <option value="2">BoomPlay</option>
                            <option value="3">AudioMack</option>
                        </select>
                    </>
                    }
                    {formInfo.category === "1" && 
                    <>
                        <label>Choose Type of Movies or Series</label>
                        <select name="movieType" id="movieType" onChange={myChangeHandler} value={formInfo.movieType}>
                            <option value="">Select...</option>
                            <option value="0">Movie</option>
                            <option value="1">Short Film</option>
                            <option value="2">Series</option>
                        </select>
                        <label>Where to find it</label>
                        <select name="findIt" id="findIt" onChange={myChangeHandler} multiple value={formInfo.findIt}>
                            <option value="">Select...</option>
                            <option value="0">Netflix</option>
                            <option value="1">IrokoTv</option>
                            <option value="2">Youtube</option>
                            <option value="3">Cinema</option>
                        </select>
                    </>
                    }
                    {formInfo.category === "2" && 
                    <>
                        <label>What Type of Food?</label>
                        <input type="text" name="food" id="food" placeholder="Food" className="input-box" onChange={myChangeHandler} value={formInfo.food}/>
                        <label>Where to find it</label>
                        <input type="text" name="findIt" id="findIt" placeholder="Where's the address?" className="input-box" onChange={myChangeHandler} value={formInfo.findIt}/>

                    </>
                    }
                    <input type="text" name="rating" id="rating" placeholder="What's the rating?" className="input-box" onChange={myChangeHandler} value={formInfo.rating}/>
                    <label>Upload Media</label>
                    <input type="file" name="files[]" multiple  onChange={myChangeHandler}  ref={ref} />
                    <textarea name="comment" id="comment" className="input-box" placeholder="What was your review comment?" onChange={myChangeHandler} value={formInfo.comment}/>
                    <input type="submit" value="Upload Entry" name="submit" id="submit"/>
                    {isSubmitted && 
                        <>
                            <span>Documents has been uploaded.</span>
                            <button type='button' onClick={() => setisSubmitted(false)}>CLEAR</button>
                        </>}
                </form>
            </>          
    );

}

export default UploadMedia;