import React from "react";
import Thumbnail from "./Thumbnail";
import style from './../css/section.module.css';
import theme from './../css/theme.module.css';
import { useSelector } from "react-redux";
import CSSTransition from "react-transition-group/CSSTransition";
import { TransitionGroup } from "react-transition-group";

const animationTiming = {
    enter: 600,
    exit: 800
};

const Section = (props) => {
    const isDay = useSelector(state => state.misc.isDay);
    


    return(
            
            <section className={style.section} id={props.id || null}>
            
            {props.isSubmenu ?
            <CSSTransition 
            mountOnEnter 
            unmountOnExit 
            in={props.isLoading} 
            timeout={animationTiming}
            classNames={{
                enter: '',
                enterActive: theme['FadeIn'],
                exit: '',
                exitActive: theme['FadeOut']
            }}
            >
             <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.title}</h3>
             </CSSTransition>
             : 
             <CSSTransition 
            mountOnEnter 
            unmountOnExit 
            in={props.isLoading} 
            timeout={animationTiming}
            classNames={{
                enter: '',
                enterActive: theme['FadeIn'],
                exit: '',
                exitActive: theme['FadeOut']
            }}
            >
             <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.title}</h2>
             </CSSTransition>}
            <TransitionGroup>
            {
                 props.data.map((item, i) => {
                        
                        return <CSSTransition 
                        mountOnEnter 
                        unmountOnExit 
                        in={props.isLoading} 
                        timeout={animationTiming}
                        classNames={{
                            enter: '',
                            enterActive: theme['FadeIn'],
                            exit: '',
                            exitActive: theme['FadeOut']
                        }}
                        key={item.id}>
                            <Thumbnail  category={item.category} title={item.title} url={item.url} rating={item.rating}/>
                        </CSSTransition>
                    })
                }
            </TransitionGroup>
        </section>
    );
};

export default Section;