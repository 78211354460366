import twitter from '../../../img/twitter.png';
import insta from '../../../img/instagram.png';
import linked from '../../../img/linkedin.png'

export const socialMedia = (twitterUrl,instaUrl,linkedInUrl) =>{
    return(
        <div className="social-team">
            <a href={twitterUrl}>
                <img src={twitter} alt="twitter" />
            </a>
            <a href={instaUrl}>
                <img src={insta} alt="instagram" />
            </a>
            <a href={linkedInUrl}>
                <img src={linked} alt="linkedIn" />
            </a>

        </div>
    );
}

