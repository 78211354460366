import React,{useEffect,useState,useRef} from 'react';
import SocialMedia from './socialmedia';
import { HashLink } from 'react-router-hash-link';
import { Link,useHistory } from 'react-router-dom';
import style from './../css/header.module.css';
import { BASE_URL, GET_RATINGS } from '../endpoint';
import SearchIcon from './../img/search.png';
import { getBelleRating, returnUrl } from '../util/functions';
import { useDispatch, useSelector } from 'react-redux';
import theme from './../css/theme.module.css';
import SignUpLogin from './SignUpLogin';
import { getUserScoreFunc, signOutFunc, toggleModal } from '../store/misc-action';
import { modalType } from '../util/staticdata';

const Header = ()=>{

    const [width,setWidth] = useState(window.innerWidth);
    const [showMenu,setShowMenu] = useState(false);
    const [showSearch,setShowSearch] = useState(false);
    const [search,setSearchField] = useState('');
    const [autoSearchArray,setAutoSearch] = useState([]);
    const [isSearching, setisSearching] = useState(false);
    const searchResults = useRef(null);
    const searchResultsMob = useRef(null);
    const history = useHistory();
    const isDay = useSelector(state => state.misc.isDay);
    const modalObj = useSelector(state => state.misc.modalObj);
    const signIn = useSelector(state => state.misc.signInObj);
    const dispatch = useDispatch();


    const handleResize = () => {
        setWidth(window.innerWidth);
    };


    
    useEffect(()=>{
        
        let autoSearch = async () => {
            if(search !== ''){
                const jsonObj = JSON.stringify(search);
                setisSearching(true);
                const response = await fetch(BASE_URL + GET_RATINGS,{method : "POST",body : jsonObj});
                const data = await response.json();
                setAutoSearch(data);
            }      
        }
        autoSearch();
        
    },[search])

    let clearResults = (e) => {
         if((searchResults.current && !searchResults.current.contains(e.target)) || 
         (searchResultsMob.current && !searchResultsMob.current.contains(e.target))){
             //console.log(e.target);
             setisSearching(false);
         }
     }


    useEffect(() => {
        if(signIn.id){
            dispatch(getUserScoreFunc(signIn.id));
        }

    },[signIn.id, dispatch])

    useEffect(()=>{
        //var searchResults = document.querySelector('.search-results');
        //var searchResultsMob = document.querySelector('.search-results-mob');
        
        window.addEventListener("click", clearResults);
        return () => {
            // Unbind the event listener on clean up
           window.removeEventListener("click", clearResults);
        };
    },[searchResults])

    const setSearch = () => {
        setShowSearch(!showSearch);
    }
    
    const setMenu = () => {
        setShowMenu(!showMenu);
    };

    const myChangeHandler = (e) => {
        setSearchField((prevState) => {
            return {
              ...prevState,
              [e.target.name]: e.target.value,
            };
          });
    }

    const submitForm = (e) =>{
        e.nativeEvent.stopImmediatePropagation();
           if(search.search !== '')
                history.push('/search/' + search.search)
       }

    useEffect(() =>{

        window.addEventListener("resize", handleResize);

    },[])

    return(
            <div className={style["header-nav"]}>
                {modalObj.isActive && <SignUpLogin/>}
                <h1><Link to={'/'}>Bad <br/>Belle</Link></h1>
                {width > 1011 &&<>
                <div style={{"display":"flex","flexDirection":"row","position":"relative"}}>
                    <form style={{"border":"1px solid white","display":"flex","flexDirection":"row","width":"100%"}}
                    onSubmit={submitForm}>
                        <input name='search' type="text" placeholder='What review are you looking for?' style={{"borderStyle":"none","width":"300px","padding":"1em"}} 
                        onChange={myChangeHandler}/>
                        <button style={{"display":"flex","alignItems":"center","justifyContent":"center","backgroundColor":"red","borderStyle":"none","padding":"0.5em"}}>
                            <img src={SearchIcon} alt='search-icon' style={{"width":"16px","padding":"0.5rem"}} />
                        </button>
                    </form>
                    {autoSearchArray.length > 0 && isSearching &&
                    <div className={style['search-results']} ref={searchResults}>
                        { 
                        autoSearchArray.map((item, i) => {
                        return (
                            <Link to={returnUrl(item.category,item.title)} className={`${style['thumbnail']} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
                                <div className={style["img-logo"]}>
                                    <img src={item.url} alt={item.title}/>
                                </div>
                                <div className={style['description']}>
                                    <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{item.title}</h3>
                                    <h4 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(item.rating)}</h4>
                                </div>
                            </Link> 
                        )
                        })
                        }
                    </div>}
                </div>
                 <ul className={style["menu-nav"]}>
                    <li><Link to={'/music'}>MUSIC</Link></li>
                    <li><Link to={'/movies'}>MOVIES & SERIES</Link></li>
                    {/* <li><Link to={'/news'}>NEWS</Link></li> */}
                </ul>
                </>}
                {showMenu && <ul className={style["menu-mob-nav"]}>
                    <li><Link to={'/music'}>MUSIC</Link></li>
                    <li><Link to={'/movies'}>MOVIES & SERIES</Link></li>
                    {/* <li><Link to={'/news'}>NEWS</Link></li> */}
                </ul>}
                {showSearch && <div className={style["search-mob-nav"]}>
                    <form style={{"border":"1px solid red","display":"flex","flexDirection":"row","width":"auto"}}
                    onSubmit={submitForm}>
                        <input name='search' type="text" placeholder='What review are you looking for?' style={{"borderStyle":"none","width":"50vw","padding":"1em"}}
                        onChange={myChangeHandler} />
                        <button style={{"display":"flex","alignItems":"center","justifyContent":"center","backgroundColor":"red","borderStyle":"none","padding":"0.5em","border":"1px solid white"}}>
                            <img src={SearchIcon} alt='search-icon' style={{"width":"16px","padding":"0.5rem"}} />
                        </button>
                    </form>
                    {autoSearchArray.length > 0 && isSearching &&
                    <div className={style['search-results-mob']} ref={searchResultsMob}>
                        { 
                        autoSearchArray.map((item, i) => {
                        return (
                            <Link to={returnUrl(item.category,item.title)} className={`${style['thumbnail']} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
                                <div className={style["img-logo"]}>
                                    <img src={item.url} alt={item.title}/>
                                </div>
                                <div className={style['description']}>
                                    <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{item.title}</h3>
                                    <h4 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(item.rating)}</h4>
                                </div>
                            </Link> 
                        )
                        })
                        }
                    </div>}
                </div>}
                {width <= 1011 &&
                <div style={{"display":"flex","flexDirection":"row","justifyContent":"center","alignItems":"center"}}> 
                <button style={{"display":"flex","alignItems":"center","justifyContent":"center","backgroundColor":"red","borderStyle":"none","padding":"0.5em"}}
                onClick={() => setSearch()}
                >
                            <img src={SearchIcon} alt='search-icon' style={{"width":"16px","padding":"0.5rem"}} />
                        </button>
                        <div className={style["menu-mob"]} onClick={() => setMenu()}>
                    <div></div>
                    <div></div>
                    <div></div>                        
                </div>
                </div>}
                <div className={style["sub-menu"]}>
                    <HashLink to={'/about#bellemeter'}>What's the Bellemeter?</HashLink>
                    { !signIn.username ?
                    <div className={style["loggedout-info"]}>
                        <button onClick={() => dispatch(toggleModal(modalType[0],true))}>Sign Up!</button>
                        <button onClick={() => dispatch(toggleModal(modalType[1],true))}>Login</button>
                    </div>
                    :
                    <div className={style["loggedin-info"]}>
                        <span>Hi {signIn.username}!</span>
                        <button onClick={() => dispatch(signOutFunc())}>Logout</button>
                    </div>
                    }
                </div>
                <div className={style["social-media"]}>
                    <SocialMedia/>
                </div>
            </div>
    );

}

export default Header;