import React,{ useEffect, useState} from 'react';
import AddRTContext from './context';
import { BASE_URL, GET_RATINGS } from '../../../endpoint';
import Rating from './rating';
import axios from "axios";


const AddRt = ({endpoint}) =>{

    const [selected,setSelected] = useState([]);
    const [search,setSearch] = useState('');
    const [index,setIndex] = useState(0);
    const [submitted,setSubmitted] = useState(false);
    
    function toggleIndex(id) {
        setIndex(index => (index === 0 ? id : 0));
        setSubmitted(false);
      }

    useEffect(()=>{
        let getInfo = async () => {
            if(endpoint !== ""){
                const jsonObj = JSON.stringify(search);
                await axios.post(BASE_URL + GET_RATINGS, jsonObj)
                .then((response) => {
                    console.log(response.data)
                        setSelected(response.data);
                }).catch((error) =>{console.log(error.message)})
                
            }
        }
        getInfo();
    },[search,endpoint])

    


      const handleInputChange = (e) => {
        setSearch((prevState) => {
          return {
            ...prevState,
            [e.target.name]: e.target.value,
          };
        });
      };
   
    

    let submitRatings = (e) => {
        e.preventDefault();

    const obj = {
        "index" : index
        }
        const jsonObj = JSON.stringify(obj);
        fetch(BASE_URL + endpoint, {
            method: "POST",
            body: jsonObj
            })
        .then((response) => {
            return response.text();
        })
        .then((data) => {
            setIndex(0);
            setSubmitted(true);
        });
    }

    return(
        <AddRTContext.Provider value={{index,toggleIndex}}>
            <div>
                <div>
                    {
                    selected.length > 0 && selected.map((item, i) => {
                        return (
                            <Rating item={item} id={i}/> 
                        )
                        })
                    }
                </div>
                <form className="submit-form">
                    <input id="search" type="text" name="search" onChange={handleInputChange}/>
                    <input type="submit" value="Add Rating" id="submit" name="submit" onClick={submitRatings}/>
                </form>
                {submitted && <>
                <p>Successfully Submitted!</p>
                <button type='button' onClick={()=>{setSubmitted(false)}}>Clear</button>
                </>}
            </div>
        </AddRTContext.Provider>
    );

}

export default AddRt;