import React,{useEffect,useState} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import useGaTracker from '../../useGATracker';
import { latestMovieFunc, latestSeriesFunc, movieMonthFunc, topMovieFunc, topSeriesFunc } from '../../store/movie-action';
import Section from '../../components/Section';
import DescriptionShell from '../../components/DescriptionShell';
import styles from './css/index.module.css';
import theme from './../../css/theme.module.css';
import LoadingSection from '../../components/LoadingSection';


const Movies = () => {
    const dispatch = useDispatch();
    const latestMovies = useSelector(state => state.movie.latestMovies);
    const topMovies = useSelector(state => state.movie.topMovies);
    //const latestSeries = useSelector(state => state.movie.latestSeries);
    const topSeries = useSelector(state => state.movie.topSeries);
    const movieMonth = useSelector(state => state.movie.movieMonth);
    const isDay = useSelector(state => state.misc.isDay);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    useGaTracker();

    

    useEffect(()=>{
        setIsLoading(true);
        dispatch(latestMovieFunc());
        dispatch(topMovieFunc());
        dispatch(latestSeriesFunc());
        dispatch(topSeriesFunc());
        dispatch(movieMonthFunc());
    },[dispatch])

    useEffect(()=>{
        if(topMovies.length > 0){
            setIsLoading(false);
            setIsLoaded(true);
        }
    },[topMovies.length])

    return(
        <>
            <LoadingSection isLoading={isLoading} />
            <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>Movies | Bad Belle</title>
                <meta name="description" content="This is the Bad Belle Movies page. Check here to see our rating of movies."></meta>
                <link rel="canonical" href="https://badbelle.ng/movies" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle Movies Page" />
                <meta property="og:description" content="This is the Bad Belle Movies page. Check here to see our rating of movies." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/movies" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle Movies Page"/>
                <meta name="twitter:description" content="This is the Bad Belle Movies page. Check here to see our rating of movies."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
            <div id="header"  >
                <Header/>  
                <div className={styles["movie-titles"]}>
                    <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Movies & Series</h2>
                    <label><HashLink to={'/movies#topMovies'}>Top Movies</HashLink></label>
                    <label><HashLink to={'/movies#topSeries'}>Top Series</HashLink></label>
                    <label><HashLink to={'/movies#latestMovies'}>Latest Movies</HashLink></label>
                    <label><HashLink to={'/movies#latestSeries'}>Latest Series</HashLink></label>
                </div>
                <Section title="Top Movies" id="topMovies" data={topMovies} isSubmenu={true} isLoading={isLoaded}/>
                <Section title="Top Series" id="topSeries" data={topSeries} isSubmenu={true} isLoading={isLoaded}/>
                <DescriptionShell title="Movie of the Month" data={movieMonth} index={0} isLoading={isLoaded}/>
                <Section title="Latest Movies" id="latestMovies" data={latestMovies} isSubmenu={true} isLoading={isLoaded}/>
{/*                 <Section title="Latest Series" id="latestSeries" data={latestSeries} isSubmenu={true} isLoading={isLoaded}/>
 */}            </div>
            <div id="body"> 
            </div>
            <Footer/>
            </div>
        </>
    );

}

export default Movies