import { useSelector } from 'react-redux';
import theme from './../../../css/theme.module.css';

const FindIt = (props) =>{
    const isDay = useSelector(state => state.misc.isDay);

    const FindItArr = props.data.split(",");
    if(typeof FindItArr == "string"){
        return(
            <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                {props.data}
            </p>
        );
    }
    else
    {
        if(props.category === 0){
            let musicArray = ["Spotify","Apple Music","Boomplay","AudioMack"];
            return(
                <ul>
                    {FindItArr.length > 0 && FindItArr.map((item, i) => {
                    return (
                        <li key={item} className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                          {musicArray[item]}
                        </li>
                    )
                    })}
                </ul>
            )
            
        }
        else if(props.category === 1){
            let movieArray = ["NetFlix","IrokoTv","YouTube","Cinema"];
            return(
                <ul>
                    {FindItArr.map((item, i) => {
                    return (
                        <li key={item} className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                          {movieArray[item]}
                        </li>
                    )
                    })}
                </ul>
            )
        }
        return<p></p>
    }
};

export default FindIt;