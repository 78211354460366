import React,{useCallback, useEffect,useState} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './css/index.module.css';
import {useParams, useLocation} from 'react-router-dom';
import { decodeURL, getBelleRating, getCat } from '../../util/functions';
import FindIt from './components/FindIt';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { useSelector, useDispatch } from 'react-redux';
import { clearCriticInfoFunc, getCriticInfoFunc, signOutFunc, toggleModal } from '../../store/misc-action';
import theme from './../../css/theme.module.css';
import LoadingSection from '../../components/LoadingSection';
import CSSTransition from "react-transition-group/CSSTransition";
//import { REVIEWS } from './components/testData';
import Heart from './components/heart';
import StarRating from './components/StarRating';
import { modalType } from '../../util/staticdata';
import SignUpLogin from '../../components/SignUpLogin';
import { ADD_REVIEW, BASE_URL, EDIT_REVIEW, GET_REVIEW_LIST, GET_REVIEW_PAGES, GET_USER_REVIEW, TOGGLE_LIKE } from '../../endpoint';
import Score from '../../components/Score';

const animationTiming = {
    enter: 600,
    exit: 800
};

const ItemCritic = () =>{

    let { topicId } = useParams();
    let { path } = useLocation();
    const dispatch = useDispatch();
    const dataArray = useSelector(state => state.misc.criticInfo);
    const isDay = useSelector(state => state.misc.isDay);
    const modalObj = useSelector(state => state.misc.modalObj);
    const signIn = useSelector(state => state.misc.signInObj);
    const score = useSelector(state => state.misc.userScore);
    const [isLoading,setIsLoading] = useState(true);
    const [isLoaded,setIsLoaded] = useState(false);
    const [userReview, setUserReview] = useState({ comment : "", rating : "", exists: 0});
    const [isEditable, setToggleEdit] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [reviewList, setReviewList] = useState([]);

    useGaTracker();
    //console.log(dataArray);


    useEffect(() => {
        setIsLoading(true);
        let topic = decodeURL(topicId);
       dispatch(getCriticInfoFunc(topic));

       return () =>{
           dispatch(clearCriticInfoFunc());
       }
        
    },[topicId,dispatch])

    useEffect(() => {
        setIsLoading(true);
        if(dataArray.length > 0){
            setIsLoading(false);
            setIsLoaded(true);
        }
    },[dataArray.length]);

    let getTotalPages = useCallback(async () => {
        if(dataArray.length > 0){
            await fetch(BASE_URL + GET_REVIEW_PAGES + "?rtlistId=" + dataArray[0].id,
                {
                    method : "GET", 
                })
                .then((response) => response.json())
                .then((data) => {
                    setTotalPages(data.total)
                })
                .catch((error) => console.log(error));
        }
    },[dataArray]);

    let getReviewList = useCallback(async () => {
        if(dataArray.length > 0){
            let url = BASE_URL + GET_REVIEW_LIST + "?rtlistId=" + dataArray[0].id + "&page=" + currentPage;
            url += signIn.id ? "&userId=" + signIn.id : "";
            await fetch(url,
                {
                    method : "GET", 
                })
                .then((response) => response.json())
                .then((data) => {
                    setReviewList(data);
                })
                .catch((error) => console.log(error));
        }
    },[dataArray,currentPage,signIn.id]);

    let toggleLike = async (index, reviewId, userReviewId) => {
        
        if(signIn.id){
            let reviewArray = [...reviewList];
            if(reviewArray[index].liked === 0){
                reviewArray[index].liked = 1;
                reviewArray[index].likeCount = +reviewArray[index].likeCount + 1;
            }
            else if(reviewArray[index].liked === 1){
                reviewArray[index].liked = 0;
                reviewArray[index].likeCount = +reviewArray[index].likeCount - 1;
            }
            setReviewList(reviewArray);
            await fetch(BASE_URL + TOGGLE_LIKE,
                {
                    method : "POST",
                    body : JSON.stringify({
                        userId : signIn.id,
                        reviewId : reviewId,
                        categoryId : dataArray[0].category,
                        userReviewId : userReviewId
                    }) 
                })
                .then((response) => response.json())
                .then((data) => {
                    reviewArray[index] = {
                        ...reviewArray[index],
                        ...data
                    }
                    setReviewList(reviewArray);
                })
                .catch((error) => console.log(error));
        }
    };


    useEffect(() =>{
        getTotalPages();
        getReviewList();
    },[getTotalPages,getReviewList,isEditable])
    
    useEffect(() => {
        let getUserReview = async () => {

            if(signIn.id && dataArray.length > 0){
                var jsonObj = { 
                rtlistId : dataArray[0].id,
                userId : signIn.id
                }

                await fetch(BASE_URL + GET_USER_REVIEW,
                    {
                        method : "POST", 
                        body : JSON.stringify(jsonObj)
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setUserReview((prevState) => {
                            return{
                                ...prevState,
                                ...data,
                                rating : +data.rating
                            }
                        })
                    })
                    .catch((error) => console.log(error));

            }

        };

        getUserReview();
    },[signIn.id,dataArray])

    let setModal = (typeOfModal, active) => {
        dispatch(toggleModal(typeOfModal,active));
    }

    let setComment = (e) => {
        setUserReview((prevState) => {
            return{
                ...prevState,
                comment : e.target.value
            }
        })
    }

    let setRating = (index) => {
        setUserReview((prevState) => {
            return{
                ...prevState,
                rating : index
            }
        })
    }

    let incNum =()=>{
        if(currentPage < totalPages)
        {
            setCurrentPage(Number(currentPage) + 1);
        }
      };
      let decNum = () => {
         if(currentPage > 0)
         {
          setCurrentPage(currentPage - 1);
         }
      }

    let submitReview = async (e) => {
        e.preventDefault();

        let jsonObj = userReview;
        jsonObj.userId = signIn.id;
        jsonObj.rtlistId = dataArray[0].id;
        jsonObj.categoryId = dataArray[0].category;

        if(userReview.rating > 0 && userReview.comment.length > 9){
            if(userReview.exists === 0){
                await fetch(BASE_URL + ADD_REVIEW,
                    {
                        method : "POST", 
                        body : JSON.stringify(jsonObj)
                    })
                    .then((response) => {
                        if(response.ok){
                            setToggleEdit(false);
                            setUserReview((prevState) => {
                                return{
                                    ...prevState,
                                    exists : 1
                                }
                            });
                        }
                    })
                    .catch((error) => console.log(error));
            }else{
                await fetch(BASE_URL + EDIT_REVIEW,
                    {
                        method : "POST", 
                        body : JSON.stringify(jsonObj)
                    })
                    .then((response) => {
                        if(response.ok){
                            setToggleEdit(false);
                            setUserReview((prevState) => {
                                return{
                                    ...prevState,
                                    exists : 1
                                }
                            });
                        }
                    })
                    .catch((error) => console.log(error));   
            }
        }
    }

    return(
        <>
            {modalObj.isActive && <SignUpLogin/>}
            <LoadingSection isLoading={isLoading} />
            <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            { dataArray.length > 0 && 
           <Helmet>
                <title>{dataArray[0].title} | Bad Belle</title> 
                <meta name="description" content={dataArray[0].comment}></meta>
                <link rel="canonical" href={"https://badbelle.ng/" + path} />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={dataArray[0].title + " | Bad Belle"}/>
                <meta property="og:description" content={dataArray[0].comment} />
                <meta property="og:image" content={dataArray[0].url} />
                <meta property="og:url" content={"https://badbelle.ng/" + path} />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content={dataArray[0].title + " | Bad Belle"}/>
                <meta name="twitter:description" content={dataArray[0].comment}/>
                <meta name="twitter:image" content={dataArray[0].url}/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>}
                <div id="header">
                    <Header/>
                </div>
                <div id="body" className={styles.critic}>
                { dataArray.length > 0 && 
                    <CSSTransition 
                    mountOnEnter 
                    unmountOnExit 
                    in={isLoaded} 
                    timeout={animationTiming}
                    classNames={{
                        enter: '',
                        enterActive: theme['FadeIn'],
                        exit: '',
                        exitActive: theme['FadeOut']
                    }}
                    >
                    <div>
                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getCat(dataArray[0].category)}</label>
                        <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{dataArray[0].title}</h2>
                        <div className={styles["album-cover"]}>
                            <img src={dataArray[0].url} alt={dataArray[0].title} />
                        </div>
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(dataArray[0].rating)}</p>
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{dataArray[0].comment}</p>
                        <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Where to find it</h3>
                        <FindIt category={dataArray[0].category} data={dataArray[0].findIt}/>
                    </div>
                    </CSSTransition>
                    }   
                    <div className={styles["audience-rating"]}>
                        <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Audience Reviews</h3>
                        {signIn.id ? 
                        (dataArray.length > 0 && 
                        <form className={styles["audience-rating-form"]} onSubmit={(e) => submitReview(e)}>
                            <div className={styles["form-header"]}>
                                <div className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                                    <p>Hi <span>{signIn.username}</span></p>
                                    <Score musicScore={score.musicScore} movieScore={score.movieScore}/>
                                </div>
                                <button onClick={() => dispatch(signOutFunc())}>Logout</button>
                            </div>
                            {isEditable ?
                            <>
                                <div>
                                    <div>
                                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Score</label>
                                        <StarRating rating={userReview.rating} setRating={setRating}/>
                                    </div>
                                    <div>
                                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Comment</label>
                                        <textarea placeholder="Tell us what you think." value={userReview.comment} onChange={(e) => setComment(e)}></textarea>
                                    </div>
                                </div>
                                <p style={{"fontSize" : "12px", "letterSpacing" : "0.8px", "color" : "red", "marginTop" : "8px", "textAlign" : "left"}}>You can't submit unless you put a score and have atleast 10 characters for the comment</p>
                                <button onClick={() => setToggleEdit(false)}>Cancel</button>
                                <input type={"submit"} value="Submit" style={userReview.rating > 0 && userReview.comment.length > 9  ? {backgroundColor : "red"} : {backgroundColor : "grey"}} />
                            </>
                            :
                            <>
                                <div className={styles["not-editable"]}>
                                    <button onClick={() => setToggleEdit(true)}>{userReview.exists === 1 ? "EDIT REVIEW" : "ADD REVIEW" }</button>
                                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{userReview.exists === 0 ? `Click the link above to add your own Review.` : `You've succesfully reviewed this content. Click the link above to edit your own Review.`}</p>
                                </div>
                            </>
                            }
                        </form>)
                        :
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Want to leave a review?<br/><button onClick={() => setModal(modalType[0],true)}>Sign Up!</button> or <button onClick={() => setModal(modalType[1],true)}>Login</button> first.</p>
                        }
                        <ul>
                            {reviewList.length > 0 && reviewList.map((review, index) => 
                            <li key={review.id}>
                                <div>
                                    <div>
                                        <span>{review.username}</span>
                                        <Score musicScore={review.musicScore} movieScore={review.movieScore}/>
                                    </div>
                                    <div className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                                        <span className={styles['stars']}>&#9733;</span>
                                        <span>
                                            {review.rating}/10
                                        </span>
                                    </div>
                                </div>
                                <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{review.comment}</p>
                                <div>
                                    <button onClick={() => toggleLike(index, review.id, review.user_id)}>
                                        <span className={`${signIn.id ? (review.liked === 1 ? styles["liked"] : (isDay ? theme['is-day-text'] : theme['is-night-text'])) : styles["isInactive"]}`}>{review.likeCount}</span>
                                        <Heart isDay={isDay} isLiked={review.liked === 1} inactive={signIn.id === null}/>
                                    </button>
                                </div>
                            </li>
                            )}
                        </ul>
                        <div className={styles["pages-nav"]}>
                            {1 < currentPage && <button onClick={decNum}>Previous</button>}
                            {currentPage !== totalPages && totalPages > 1 && <button onClick={incNum}>Next</button>}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );

}

export default ItemCritic;
