import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route   
  } from "react-router-dom";
import About from './routes/About';
import Careers from './routes/Careers';
import News from './routes/News';
import Help from './routes/Help';
import Home from './routes/Home';
import ItemCritic from './routes/ItemCritic';
import Movies from './routes/Movies';
import Music from './routes/Music';
import Team from './routes/Team';
import UploadMedia from './routes/UploadMedia';
import ProtectedRoute from './components/ProtectedComponents';
import Login from './routes/Login';
import {useEffect} from 'react';
import { setIsDay } from './store/misc-action';
import {  useDispatch } from 'react-redux';
import Search from './routes/Search';
import EmailVerify from './routes/VerifyEmail';
import ResetPassword from './routes/ResetPassword';


const Routing = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setIsDay());
  },[dispatch]);


    return(
        <Router>
            <Switch>
          <Route path="/about">
            <About/>
          </Route>
          <Route path="/music1">
            <ItemCritic/>
          </Route>
          <ProtectedRoute path="/portal" component={UploadMedia} />
          <Route path="/login">
            <Login/>
          </Route>
          <Route path="/team">
            <Team/>
          </Route>
          <Route path="/careers">
            <Careers/>
          </Route>
          <Route path="/help">
            <Help/>
          </Route>
          <Route exact path="/news">
            <News/>
          </Route>
          <Route path="/news/:topicId">
            <ItemCritic/>
          </Route>
          <Route exact path="/movies">
            <Movies/>
          </Route>
          <Route path="/movies/:topicId">
            <ItemCritic/>
          </Route>
          <Route exact path="/music">
            <Music/>
          </Route>
          <Route path="/music/:topicId">
            <ItemCritic/>
          </Route>
          <Route path="/search/:search">
            <Search/>
          </Route>
          <Route path="/verifyEmail/:id">
            <EmailVerify/>
          </Route>
          <Route path="/resetPassword/:id/:hash">
            <ResetPassword/>
          </Route>
          <Route path="/">
            <Home/>
          </Route>
        </Switch>
        </Router>
    );
}

export default Routing;