import './App.css';
import Routing from './Router.js';
import { Provider } from 'react-redux';
import store from './store';
import { HelmetProvider } from 'react-helmet-async';


function App() {
  

  return (
    <HelmetProvider>
      <Provider store={store}>
        <Routing/>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
