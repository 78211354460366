import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './css/index.module.css';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { useSelector } from 'react-redux';
import theme from './../../css/theme.module.css';

const Careers = () => {
    const isDay = useSelector(state => state.misc.isDay);
    useGaTracker();

    return(
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>Careers | Bad Belle</title>
                <meta name="description" content="This is the Bad Belle Careers page. Check here to see if we are hiring."></meta>
                <link rel="canonical" href="https://badbelle.ng/careers" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle Careers Page" />
                <meta property="og:description" content="This is the Bad Belle Careers page. Check here to see if we are hiring." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/careers" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle Careers Page"/>
                <meta name="twitter:description" content="This is the Bad Belle Careers page. Check here to see if we are hiring."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
        <div id="header" className={styles.header} >
            <Header/>
            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Careers</h2>
            <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Sorry we are currently not hiring.</p>
        </div>
        <div id="body"> 
        </div>
        <Footer/>
    </div>

    );

}

export default Careers