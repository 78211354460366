import { configureStore } from '@reduxjs/toolkit';

import miscSlice from './misc-slice';
import movieSlice from './movie-slice';
import musicSlice from './music-slice';

const store = configureStore({
    reducer : {movie : movieSlice.reducer, music: musicSlice.reducer,misc : miscSlice.reducer}
})

export default store;