import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { modalType } from "../util/staticdata";
import styles from "./../css/signuplogin.module.css";
import { useDispatch, useSelector } from 'react-redux';
import theme from "./../css/theme.module.css";
import { setLogInFunc, setSignInFunc, signOutFunc, toggleModal } from "../store/misc-action";
import { checkEmailExistsOrValid, checkUsernameValid, IsEmailValid, strongPasswordCheck } from "../util/functions";
import axios from "axios";
import { BASE_URL, REQUEST_PASSWORD_RESET } from "../endpoint";
import Loader from './../img/pulse.gif';



const SignUp = () => {
    const dispatch = useDispatch();
    const isDay = useSelector(state => state.misc.isDay);
    const [signUp,setSignUp] = useState({username : "", email : "", password : ""});
    const signIn = useSelector(state => state.misc.signInObj);
    const [isValidUser,setIsValidUser] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [emailMessage, setEmailMessage] = useState(false);
    const [passwordStrong, setPasswordStrong] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() =>{

        if(signIn.id){
            setIsSubmitting(false);
            dispatch(toggleModal("",false));
        }

    },[signIn.id,dispatch])

    let  submitForm = (e) => {
        e.preventDefault();
        if(isValidUser && emailError === "" && passwordStrong && signUp.username !== "" && signUp.email !== "" && signUp.password !== ""){
            setIsSubmitting(true);
            dispatch(setSignInFunc(signUp));
            //console.log(signUp);
        }
    }

    const handleInputChange = (e) => {
        setSignUp((prevState) => {
            return {
                ...prevState,
                [e.target.name] : e.target.value
            }
        });

    }

    const setUserIsValid = (username) => {
        checkUsernameValid(username)
        .then((response) => {
            setIsValidUser(response);
        });
    }

    const checkEmail = (email) => {
        checkEmailExistsOrValid(email)
        .then((response) => {
            setEmailError(response);
        })
    } 

    const checkPasswordStrong = (password) => {
        setPasswordStrong(strongPasswordCheck(password));
    }

    
    
    return(
    <div className={`${styles["modal"]} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
        <div>
            <div>
                <h2>Sign Up!</h2>
                <button onClick={() => dispatch(toggleModal("",false))} className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>&#10005;</button>
            </div>
            {isSubmitting ? 
             <img src={Loader} className={styles.gif} alt='loading'/>
            :
            <>
                <form onSubmit={(e) => submitForm(e)}>
                    <div className={styles["username-container"]}>
                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Username</label>
                        <input name="username" type={"text"} value={signUp.username} onChange={(e) => {handleInputChange(e);setUserIsValid(e.target.value);}}/>
                        {signUp.username.length > 0 && (isValidUser ? 
                        <span style={{color : "green"}}>&#10003;</span> : 
                        <span style={{color : "red"}}>&#10005;</span>)}
                    </div>
                    <div>
                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Email</label>
                        <input name="email" type={"text"} value={signUp.email} onChange={(e) => {handleInputChange(e);checkEmail(e.target.value)}} onBlur={() => setEmailMessage((prevState) => emailError !== "")}/>
                        {emailMessage && <p style={{color : "red",fontSize : "12px", margin : "4px", letterSpacing : "0.8px"}}>{emailError}</p>}
                    </div>
                    <div>
                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Password</label>
                        <input name="password" type={"password"} value={signUp.password} onChange={(e) => {handleInputChange(e);checkPasswordStrong(e.target.value);}} />
                        <>
                            <p style={{color : "red",fontSize : "12px", margin : "8px 4px", letterSpacing : "0.8px"}}>Your password must contain the following:</p>
                            <ul style={{color : "red",fontSize : "12px", margin : "4px", letterSpacing : "0.8px"}}>
                                <li>The string must contain at least 1 lowercase alphabetical character</li>
                                <li>The string must contain at least 1 uppercase alphabetical character</li>
                                <li>The string must contain at least one special character</li>
                                <li>The string must be eight characters or longer</li>
                            </ul>
                        </>
                    </div>
                    <button type={"submit"} style={isValidUser && emailError === "" && passwordStrong ? {backgroundColor : "red"} : {backgroundColor : "grey"}}>Sign Up!</button>
                </form>
                <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Already have an account? <button onClick={() => dispatch(toggleModal(modalType[1],true))}>Login!</button></p>
            </>}
        </div>
    </div>)
}

const Login = () => {
    const [emailMessage,setEmailMessage] = useState("");
    const [emailError,setEmailError] = useState(false);
    const [loginInfo, setLoginInfo] = useState({email : "", password : ""});
    const [invalidLogin, setInvalidLogin] = useState(false);
    const isDay = useSelector(state => state.misc.isDay);
    const signIn = useSelector(state => state.misc.signInObj);
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setLoginInfo((prevState) => {
            return {
                ...prevState,
                [e.target.name] : e.target.value
            }
        });

    }

    useEffect(() =>{

        if(signIn.id){
            dispatch(toggleModal("",false));
        }
        if(signIn.id === 0){
            setInvalidLogin(true);
            dispatch(signOutFunc());
        }

    },[signIn.id,dispatch])


    let  submitForm = (e) => {
        e.preventDefault();
        if(loginInfo.email !== "" && loginInfo.password !== "" && emailMessage === ""){
            dispatch(setLogInFunc(loginInfo));
        }

        
    }


    return(
    <div className={`${styles["modal"]} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
        <div>
            <div>
                <h2>Login!</h2>
                <button onClick={() => dispatch(toggleModal("",false))} className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>&#10005;</button>
            </div>
            <form onSubmit={(e) => submitForm(e)}>
                <div>
                    <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Email</label>
                    <input type={"text"} name="email" onChange={(e) => {handleInputChange(e);setEmailMessage(IsEmailValid(e.target.value));}} value={loginInfo.email} onBlur={(e) => setEmailError((prevState) => emailMessage !== "")}/>
                    {emailError && <p style={{color : "red",fontSize : "12px", margin : "4px", letterSpacing : "0.8px"}}>{emailMessage}</p>}
                </div>
                <div>
                    <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Password</label>
                    <input type={"password"} name="password" onChange={(e) => {handleInputChange(e)}} value={loginInfo.password}/>
                </div>
                {invalidLogin && <p style={{color : "red",fontSize : "12px", margin : "4px", letterSpacing : "0.8px"}}>Email/Password is wrong.</p>}
                <button type={"submit"} style={loginInfo.email !== "" && loginInfo.password !== "" && emailMessage === "" ? {backgroundColor : "red"} : {backgroundColor : "grey"}}>Login</button>
            </form>
            <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Forgotten Password? <button onClick={() => dispatch(toggleModal(modalType[2],true))}>Click here!</button></p>
            <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Don't have an account? <button onClick={() => dispatch(toggleModal(modalType[0],true))}>Sign Up!</button></p>
        </div>
    </div>)
}

const ForgotPass = () => {
    const isDay = useSelector(state => state.misc.isDay);
    const [emailInfo, setEmailInfo] = useState({email : ""});
    const [passwordResetStatus, setPasswordResetStatus] = useState({status : 0, text : ""});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setEmailInfo((prevState) => {
            return {
                ...prevState,
                [e.target.name] : e.target.value
            }
        });

    }

    let  submitForm = async (e) => {
        setIsSubmitting(true);
        e.preventDefault();

        await axios.post(BASE_URL + REQUEST_PASSWORD_RESET, JSON.stringify(emailInfo))
        .then((response) => {
            setPasswordResetStatus(response.data);
            setIsSubmitting(false);
        })
        .catch((error) => console.log(error));
        //dispatch(toggleModal("",false));
    }


    return(
    <div className={`${styles["modal"]} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
        <div>
            <div>
                <h2>Forgot Password?</h2>
                <button onClick={() => dispatch(toggleModal("",false))} className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>&#10005;</button>
            </div>
            {isSubmitting ?
            <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Sending Password Request. Hold on.</p> :
            ((passwordResetStatus.status === 0 || passwordResetStatus.status === 2) &&
            <form onSubmit={(e) => submitForm(e)}>
                <div>
                    <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Email</label>
                    <input type={"email"} name="email" onChange={(e) => {handleInputChange(e)}} value={emailInfo.email}/>
                </div>
                <button type={"submit"}>Submit</button>
            </form>
            )}
            {passwordResetStatus.status === 1 && <p style={{"color" : "green"}}>{passwordResetStatus.text}</p>}
            {passwordResetStatus.status === 2 && <p style={{"color" : "red"}}>{passwordResetStatus.text}</p>}
            {false &&<p>Don't have an account? <button onClick={() => dispatch(toggleModal(modalType[0],true))}>Sign Up!</button></p>}
        </div>
    </div>)
}

const SignUpOrLogin = () => {
    const modalObj = useSelector(state => state.misc.modalObj);
    
    
    return<>
        <div className={styles["background"]}></div>      
        {modalObj.type === modalType[0] && <SignUp/>}  
        {modalObj.type === modalType[1] && <Login/>}  
        {modalObj.type === modalType[2] && <ForgotPass/>}  
    </>
}

const SignUpLogin = () => {
    return<>
        {ReactDOM.createPortal(<SignUpOrLogin/>,document.getElementById("sign-in"))}
    </>
}

export default SignUpLogin;