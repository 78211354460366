import { BASE_URL, GET_LATEST_ALBUMS, GET_LATEST_SONGS, GET_SONG_WEEK, GET_TOP_ALBUMS, GET_TOP_SONGS } from "../endpoint"
import { musicAction } from "./music-slice"

export const latestMusicFunc = () => {
    return async (dispatch) => {
        const getLatestMusic = async () => {
            const response = await fetch(BASE_URL + GET_LATEST_SONGS, {method: "GET"});
            if(!response.ok){
                throw new Error("Could not get latest Music");
            }

            const data = await response.json();

            return data;
        }
        try{
            const latestMusic = await getLatestMusic();
            dispatch(musicAction.getLatestMusic(latestMusic || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const topMusicFunc = () => {
    return async (dispatch) => {
        const getTopMusic = async () => {
            const response = await fetch(BASE_URL + GET_TOP_SONGS, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get latest Music");
            }

            const data = await response.json();
            return data;
        }
        try{
            const topMusic = await getTopMusic();
            dispatch(musicAction.getTopMusic(topMusic || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const latestAlbumsFunc = () => {
    return async (dispatch) => {
        const getLatestAlbums = async () => {
            const response = await fetch(BASE_URL + GET_LATEST_ALBUMS, {method: "GET"});
            if(!response.ok){
                throw new Error("Could not get latest Music");
            }

            const data = await response.json();

            return data;
        }
        try{
            const latestAlbums = await getLatestAlbums();
            dispatch(musicAction.getLatestAlbums(latestAlbums || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const topAlbumsFunc = () => {
    return async (dispatch) => {
        const getTopAlbums = async () => {
            const response = await fetch(BASE_URL + GET_TOP_ALBUMS, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get latest Music");
            }

            const data = await response.json();

            return data;
        }
        try{
            const topAlbums = await getTopAlbums();
            dispatch(musicAction.getTopAlbums(topAlbums || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const weekMusicFunc = () => {
    return async (dispatch) => {
        const getWeekMusic = async () => {
            const response = await fetch(BASE_URL + GET_SONG_WEEK, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get latest Music");
            }

            const data = await response.json();
            return data;
        }
        try{
            const weekMusic = await getWeekMusic();
            dispatch(musicAction.getWeekMusic(weekMusic || []));
        }catch(error){
            console.log(error);
        }
    }
}