import React,{useState} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import './css/index.css';
import { POST_LOGIN,BASE_URL } from '../../endpoint';
import axios from "axios";

 

const Login = () => {
  const [userData, setUserData] = useState({ username: "", password: "" });
  const [errorMessage, setErrorMessage] = useState({ value: "" });

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async  (e) => {
    e.preventDefault();

    const obj = {
        "username" : userData.username,
        "pass" : userData.password,
       
    }
    const jsonObj = JSON.stringify(obj);
    await axios.post(BASE_URL + POST_LOGIN, jsonObj)
      .then((response) => {
         if(response.data.length > 0){
          localStorage.setItem("isAuthenticated", "true");
          window.location.pathname = "/portal";
         }
      }).catch((e) =>{
        setErrorMessage("Invalid username/password");
      })
  };


    return(<div id="container">
    <div id="header" className="header">
    <Header/> 
    <h1>Signin User</h1>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="form-group">
          <label>Username</label>
          <input
            className="form-control"
            type="text"
            name="username"
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            className="form-control"
            type="password"
            name="password"
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          Submit
        </button>

        {errorMessage.value && (
          <p className="text-danger"> {errorMessage.value} </p>
        )}
      </form>
    </div>
    <div id="body"> 
    </div>
    <Footer/>
    </div>
    );
}

export default Login;