const URL = [
    "http://localhost/BBmware/",
    "https://bbmware.com/BBmware/"
];

export const BASE_URL = URL[1];

export const GET_CAR = "getCarousel/";

export const GET_LATEST_SONGS = "getLatestSongs/";

export const GET_PICK = "getPick/";

export const GET_LATEST_MOVIES = "getLatestMovies/";

export const GET_THROWBACK = "getThrowback/";

export const GET_TOP_SONGS = "getTopSongs/";

export const GET_TOP_ALBUMS = "getTopAlbums/";

export const GET_LATEST_ALBUMS = "getLatestAlbums/";

export const GET_SONG_WEEK = "getSongWeek/";

export const GET_LATEST_SERIES = "getLatestSeries/";

export const GET_TOP_MOVIES = "getTopMovies/";

export const GET_TOP_SERIES = "getTopSeries/";

export const GET_MOVIE_MONTH = "getMovieMonth/";

export const GET_CRITIC_INFO = "getCriticInfo/";

export const GET_FIND_IT = "getFindIt/";

export const POST_LOGIN = "login/";

export const UPLOAD = "upload/";

export const GET_RATINGS = "getRatings/";

export const SIGN_UP = "signUp/";

export const CHECK_USER = "checkUser/";

export const CHECK_EMAIL ="checkEmail/";

export const USER_LOGIN = "userLogin/";

export const GET_USER_REVIEW = "getUserReview/";

export const ADD_REVIEW = "addReview/";

export const EDIT_REVIEW = "editReview/";

export const GET_REVIEW_PAGES = "getReviewCount/";

export const GET_REVIEW_LIST = "getUserReviewList/";

export const TOGGLE_LIKE = "toggleLike/";

export const GET_USER_SCORE = "getUserScore/";

export const VERIFY_EMAIL = "verifyEmail/";

export const REQUEST_PASSWORD_RESET = "reqPasswordReset/";

export const PASSWORD_RESET_IS_EXPIRED = "passwordResetIsExpired/";

export const RESET_PASSWORD = "resetPassword/";