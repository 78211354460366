import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import {Link} from 'react-router-dom';
import styles from './css/index.module.css';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { useSelector } from 'react-redux';
import theme from './../../css/theme.module.css';


const Help = () => {
    const isDay = useSelector(state => state.misc.isDay);
    useGaTracker();

    return(
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>Help | Bad Belle</title>
                <meta name="description" content="This is the Bad Belle Help page. Check here to understand what we do."></meta>
                <link rel="canonical" href="https://badbelle.ng/help" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle About Page" />
                <meta property="og:description" content="This is the Bad Belle Help page. Check here to understand what we do." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/help" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle Help Page"/>
                <meta name="twitter:description" content="This is the Bad Belle Help page. Check here to understand what we do."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
        <div id="header" className={styles.header} >
            <Header/>
            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Help</h2>
            <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>To understand Bad Belle. Read About Bad Belle <Link to='/about'>here</Link>.</p>
        </div>
        <div id="body"> 
        </div>
        <Footer/>
    </div>

    );

}

export default Help