import belleful from '../img/high.png'
import kampe from '../img/med.png'
import spoil from '../img/low.png'
import { BASE_URL, CHECK_EMAIL, CHECK_USER } from '../endpoint';

export const getCat = (index) => {
    
    let catArray = ["Music","Movies & Series","Food"];
    return catArray[index];

}
export const getBelleRating = (rating) => {
    if(rating < 30){
        return (
            <>
            <img src={spoil} alt="Belle Spoil" />
            {rating + "%"}
            </>
            );
    }
    if(rating >= 30 && rating < 80){
        return (
            <>
            <img src={kampe} alt="Belle Kampe" />
            {rating + "%"}
            </>
            );
    }
    if(rating >= 80){
        return (
            <>
            <img src={belleful} alt="Belleful" />
            {rating + "%"}
            </>
            );
    }

}

export const encodeURL = (str) =>{
    var res = str.replace(/ /g, "_");
    return res;
}

export const decodeURL = (str) =>{
    var res = str.replace(/_/g, " ");
    return res;
}

export const getCatUrl = (index) => {
    
    let catArray = ["music","movies","food"];
    return "/" + catArray[index] + "/";

}

export const returnUrl = (category,title) => {

    return getCatUrl(category) + encodeURL(title);

}

export const stringCut = (str,category,title) =>{
    if(str.length > 250){
        return(<>
        {str.slice(0,230)}...
        <span to={returnUrl(category,title)}>Read more</span>
        </>);
    }
    else
    {
        return str;
    }
}

export const checkUsernameValid = async (username) => {
    try{
        const response = await fetch(BASE_URL + CHECK_USER + "?username=" + username, {method: "GET"});

        if(!response.ok){
            throw new Error("Could not get usernames.");
        }

        const data = await response.json();

        if(username.length > 0){
            const isValid = data === 0 && /^(\w){3,15}$/.test(username);
            return isValid;
        }

        return false;
        
    }catch(error){
        console.log(error);
    }
}

export const checkEmailExistsOrValid = async (email) => {
    try{
        const response = await fetch(BASE_URL + CHECK_EMAIL + "?email=" + email, {method: "GET"});

        if(!response.ok){
            throw new Error("Could not get usernames.");
        }

        const data = await response.json();

        if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))){
            return "Invalid Email. Please Enter a valid Email.";
        }

        if(data === 1){
            return "Email already exists with us. Try Login in.";
        }

        return "";
        
    }catch(error){
        console.log(error);
    }
}

export const IsEmailValid = (email) => {

    if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))){
        return "Invalid Email. Please Enter a valid Email.";
    }

    return "";

}

export const strongPasswordCheck = (password) => {

    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password);

}

