import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import style from './css/index.module.css';
import working from '../../img/working.gif';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { useSelector } from 'react-redux';
import theme from './../../css/theme.module.css';


const News = () => {
    const isDay = useSelector(state => state.misc.isDay);
    useGaTracker();

    return(
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
        <Helmet>
            <title>News | Bad Belle</title>
            <meta name="description" content="This is the Bad Belle News page. Coming soon. We are working on it."></meta>
            <link rel="canonical" href="https://badbelle.ng/news" />
            <meta name="robots" content="index, follow"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Bad Belle News Page" />
            <meta property="og:description" content="This is the Bad Belle News page. Coming soon. We are working on it." />
            <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
            <meta property="og:url" content="https://badbelle.ng/news" />
            <meta property="og:site_name" content="Bad Belle" />
            <meta name="twitter:title" content="Bad Belle News Page"/>
            <meta name="twitter:description" content="This is the Bad Belle News page. Coming soon. We are working on it."/>
            <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
            <meta name="twitter:site" content="@badbelleng"/>
            <meta name="twitter:creator" content="@badbelleng"/>
        </Helmet>
        <div id="header" className={style["header"]} >
            <Header/>
            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>News</h2>
            <div className={style["food-section"]}>
                <div>
                    <img src={working} alt="working" />
                </div>
                <div>
                    <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>We are working on it.</h2>
                    <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Coming soon</h3>
                </div>
            </div>
        </div>
        <div id="body"> 
        </div>
        <Footer/>
    </div>

    );

}

export default News