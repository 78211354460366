import { BASE_URL, GET_LATEST_MOVIES, GET_LATEST_SERIES, GET_MOVIE_MONTH, GET_TOP_MOVIES, GET_TOP_SERIES } from "../endpoint";
import { movieAction } from "./movie-slice";


export const latestMovieFunc = () => {
    return async (dispatch) => {
        const getLatestMovie = async () => {
            const response = await fetch(BASE_URL + GET_LATEST_MOVIES, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get latest Movie");
            }

            const data = await response.json();

            return data;
        }
        try{
            const latestMovie = await getLatestMovie();
            dispatch(movieAction.getLatestMovie(latestMovie || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const topMovieFunc = () => {
    return async (dispatch) => {
        const getTopMovie = async () => {
            const response = await fetch(BASE_URL + GET_TOP_MOVIES, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get top Movies");
            }

            const data = await response.json();

            return data;
        }
        try{
            const topMovie = await getTopMovie();
            dispatch(movieAction.getTopMovie(topMovie || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const latestSeriesFunc = () => {
    return async (dispatch) => {
        const getLatestSeries = async () => {
            const response = await fetch(BASE_URL + GET_LATEST_SERIES, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get latest Series");
            }

            const data = await response.json();

            return data;
        }
        try{
            const latestSeries = await getLatestSeries();
            dispatch(movieAction.getLatestSeries(latestSeries || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const topSeriesFunc = () => {
    return async (dispatch) => {
        const getTopSeries = async () => {
            const response = await fetch(BASE_URL + GET_TOP_SERIES, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get top Series");
            }

            const data = await response.json();

            return data;
        }
        try{
            const topSeries = await getTopSeries();
            dispatch(movieAction.getTopSeries(topSeries || []));
        }catch(error){
            console.log(error);
        }
    }
}

export const movieMonthFunc = () => {
    return async (dispatch) => {
        const getMovieMonth = async () => {
            const response = await fetch(BASE_URL + GET_MOVIE_MONTH, {method: "GET"});

            if(!response.ok){
                throw new Error("Could not get movie of the month");
            }

            const data = await response.json();

            return data;
        }
        try{
            const movieMonth = await getMovieMonth();
            dispatch(movieAction.getMovieMonth(movieMonth || []));
        }catch(error){
            console.log(error);
        }
    }
}