import React from "react";
import HeartDay from "../../../img/heartDay.png";
import HeartNight from "../../../img/heartNight.png";
import HeartIsLiked from "../../../img/heartIsLiked.png";
import HeartNotLoggedIn from "../../../img/heartInactive.png";

const Heart = (props) => {
    if(props.inactive)
        return <img src={HeartNotLoggedIn} alt="notLoggedIn" />

    if(props.isLiked)
        return <img src={HeartIsLiked} alt="isLiked" />

    return props.isDay ? 
    <img src={HeartDay} alt="isDayHeart" /> :
    <img src={HeartNight} alt="isNightHeart" />
}

export default Heart;