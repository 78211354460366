import React,{useContext} from 'react';
import { getBelleRating } from '../../../util/functions';
import AddRTContext from './context';


const Rating = (props) =>{
    const item = props.item;
    const {index, toggleIndex} = useContext(AddRTContext);
   

    return(
        <div onClick={()=>{toggleIndex(item.id)}} className={index === item.id ? 'selected' : ''}>
            <div class="img-logo">
                <img src={item.url} alt={item.title}/>
            </div>
            <div>
                <h3>{item.title}</h3>
                <h4>{getBelleRating(item.rating)}</h4>
            </div>
        </div>
    );

}

export default Rating;