import ThumbnailDescription from "./ThumbnailDescription";
import style from './../css/descriptionshell.module.css';
import theme from './../css/theme.module.css';
import { useSelector } from "react-redux";
import CSSTransition from "react-transition-group/CSSTransition";

const animationTiming = {
    enter: 600,
    exit: 800
};

const DescriptionShell = (props) => {
    const isDay = useSelector(state => state.misc.isDay);

    return(
        <CSSTransition 
            mountOnEnter 
            unmountOnExit 
            in={props.isLoading} 
            timeout={animationTiming}
            classNames={{
                enter: '',
                enterActive: theme['FadeIn'],
                exit: '',
                exitActive: theme['FadeOut']
            }}
            >
        <section className={style.shell}>
            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{props.title}</h2>
            <ThumbnailDescription data={props.data} index={props.index} animate={false}/>
        </section>
        </CSSTransition>
    );
}

export default DescriptionShell