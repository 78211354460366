import React, { useEffect,useState } from 'react';
import Header from '../../components/header';
import { useSelector, useDispatch } from 'react-redux';
import HeaderContent from './components/HeaderContent';
import Footer from '../../components/footer';
//import TopFood from './components/topfood';
import style from './css/index.module.css';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { latestMusicFunc } from '../../store/music-action';
import Section from '../../components/Section';
import { latestMovieFunc } from '../../store/movie-action';
import { getCarouselFunc, pickDay, throwbackClassic } from '../../store/misc-action';
import DescriptionShell from '../../components/DescriptionShell';
import theme from './../../css/theme.module.css';
import LoadingSection from '../../components/LoadingSection';

const Home = ()=>{
    const dispatch = useDispatch();
    const carousel = useSelector(state => state.misc.carousel);
    const latestMusic = useSelector(state => state.music.latestMusic);
    const latestMovies = useSelector(state => state.movie.latestMovies);
    const pickOftheDay = useSelector(state => state.misc.pickDay);
    const throwbackClassicdata = useSelector(state => state.misc.throwbackClassic);
    const isDay = useSelector(state => state.misc.isDay);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useGaTracker();
    useEffect(()=>{
        setIsLoading(true);
        dispatch(getCarouselFunc());
        dispatch(latestMusicFunc());
        dispatch(latestMovieFunc());
        dispatch(pickDay());
        dispatch(throwbackClassic());
    },[dispatch])
         
    useEffect(()=>{
        if(carousel.length > 0){
            setIsLoading(false);
            setIsLoaded(true);
        }
    },[carousel.length])
    

        
   
    return(
        <>
        <LoadingSection isLoading={isLoading} />
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>Home | Bad Belle</title>
                <meta name="description" content="Bad Belle is a platform where Nigerian movies, music and food can be rated. It is an online aggregator to get the best out of the Nigerian entertainment scene. Our plan is to get our users informed about the best in the categories we select and keep them updated on what is going on around them. We have different rating systems."></meta>
                <link rel="canonical" href="https://badbelle.ng/" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle Home Page" />
                <meta property="og:description" content="Bad Belle is a platform where Nigerian movies, music and food can be rated. It is an online aggregator to get the best out of the Nigerian entertainment scene. Our plan is to get our users informed about the best in the categories we select and keep them updated on what is going on around them. We have different rating systems." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle Home Page"/>
                <meta name="twitter:description" content="Bad Belle is a platform where Nigerian movies, music and food can be rated. It is an online aggregator to get the best out of the Nigerian entertainment scene. Our plan is to get our users informed about the best in the categories we select and keep them updated on what is going on around them. We have different rating systems."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
            
            <div className={style.header}>
                <Header/>
                <HeaderContent data={carousel}/>
            </div>
            <div id="body">
                <Section title="Latest Music" data={latestMusic} isSubmenu={false} isLoading={isLoaded}/>
                <DescriptionShell title="Pick of the Day" data={pickOftheDay} index={0} isLoading={isLoaded}/>
                <Section title="Latest Movies" data={latestMovies} isLoading={isLoaded}/>
                <DescriptionShell title="Throwback Classic" data={throwbackClassicdata} index={0} isSubmenu={false} isLoading={isLoaded}/>               
            </div>
            <Footer/>
        </div>
        </>
    );

}

export default Home;