import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import './css/index.css';
import {
    Link,
    Switch,
    Route,
    useRouteMatch
  } from "react-router-dom";
import UploadMedia from './components/upload';
import Content from './components/mngcontent';


const Portal = () =>{

    let { path, url } = useRouteMatch();


    const handleLogout = () => {
        localStorage.clear();
        window.location.pathname = "/login";
      };

    return(
        <div id="container">
            <div id="header">
                <Header/>
            </div>
            <div id="body" className="body upload">
                <div>
                    <ul>
                        <li>
                            <Link to={`${url}/upload`}>Upload</Link>
                        </li>
                        <li>
                            <Link to={`${url}/contentmgnt`}>Content Management</Link>
                        </li>
                    </ul>
                    <button className="btn btn-primary" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
                <div className="content">
                <Switch>
                    <Route exact path={path}>
                    <UploadMedia/>
                    </Route>
                    <Route path={`${path}/upload`}>
                    <UploadMedia />
                    </Route>
                    <Route path={`${path}/contentmgnt`}>
                    <Content />
                    </Route>
                </Switch>
                </div>
            </div>
            <Footer/>
        </div>
    );

}

export default Portal;