import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './css/index.module.css';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import theme from './../../css/theme.module.css';
import axios from 'axios';
import { BASE_URL, PASSWORD_RESET_IS_EXPIRED, RESET_PASSWORD } from '../../endpoint';
import { strongPasswordCheck } from '../../util/functions';

const ResetPassword = () => {
    const isDay = useSelector(state => state.misc.isDay);
    let { id, hash } = useParams();
    const [verifyStatus, setVerifyStatus] = useState(2);
    const [password,setPassword] = useState("");
    const [passwordStrong, setPasswordStrong] = useState();

    useEffect(() => {
        let verifyEmail = async () => {
            await axios.post(BASE_URL + PASSWORD_RESET_IS_EXPIRED, JSON.stringify({
                id : id,
                hash : hash
            }))
            .then((response) => {
                setVerifyStatus(+response.data.isExpired);
            }).catch((error) =>{
    
            })
        }
        verifyEmail();

    },[id,hash]);

    const checkPasswordStrong = (password) => {
        setPasswordStrong(strongPasswordCheck(password));
    }

    let submitPasswordReset = async (e) => {
        e.preventDefault();

        if(passwordStrong){
            setVerifyStatus(3);
            await axios.post(BASE_URL + RESET_PASSWORD,JSON.stringify({
                id : id,
                password : password
            }))
            .then((response) => setVerifyStatus(response.data.isExpired))
            .catch((error) => {});
        }
    }

    return(
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>Reset Password | Bad Belle</title>
                <meta name="description" content="This is the reset password page for Bad Belle."></meta>
                <link rel="canonical" href="https://badbelle.ng/resetPassword" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle Reset Password Page" />
                <meta property="og:description" content="This is the Bad Belle Reset Password page." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/resetPassword" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle Reset Password Page"/>
                <meta name="twitter:description" content="This is the Bad Belle Reset Password page."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
        <div id="header" className={styles.header} >
            <Header/>
            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Reset Password</h2>
            {verifyStatus === 4 && <p style={{fontSize: "14px", letterSpacing : "0.8px", color : "red", marginTop: "16px"}}>You can't use old password. Try another password.</p>}
            {(verifyStatus === 0 || verifyStatus === 4) &&
            <div className={styles.body}>
                <form onSubmit={(e) => submitPasswordReset(e)}>
                    <div>
                        <label className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>New Password</label>
                        <input type={"password"} value={password} onChange={(e) => {setPassword(e.target.value);checkPasswordStrong(e.target.value);}}/>
                    </div>
                    <button type={"submit"} style={ passwordStrong ? {backgroundColor : "red"} : {backgroundColor : "grey"}}>Submit</button>
                </form>
                <>
                    <p style={{color : "red",fontSize : "12px", margin : "8px 4px", letterSpacing : "0.8px"}}>Your password must contain the following:</p>
                    <ul style={{color : "red",fontSize : "12px", margin : "4px", letterSpacing : "0.8px"}}>
                        <li>The string must contain at least 1 lowercase alphabetical character</li>
                        <li>The string must contain at least 1 uppercase alphabetical character</li>
                        <li>The string must contain at least one special character</li>
                        <li>The string must be eight characters or longer</li>
                    </ul>
                </>
            </div>
            }
            {verifyStatus === 3 && <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`} style={{"fontSize" : "16px", "letterSpacing" : "0.8px", "marginTop" : "16px"}}>Uploading new password. This shouldn't take too long.</p>}
            {verifyStatus === 5 && <p style={{"color" : "green", "fontSize" : "16px", "letterSpacing" : "0.8px", "marginTop" : "16px"}}>Password successfully changed</p>}
        </div>
        <div id="body"> 
        </div>
        <Footer/>
    </div>

    );

}

export default ResetPassword