import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './css/index.module.css';
import belleful from '../../img/high.png';
import kampe from '../../img/med.png';
import spoil from '../../img/low.png';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { useSelector } from 'react-redux';
import theme from './../../css/theme.module.css';

const About = () =>{
    const isDay = useSelector(state => state.misc.isDay);
    useGaTracker();

    return(
        <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
                <title>About | Bad Belle</title>
                <meta name="description" content="This is the Bad Belle About us page. Check here to understand what we do."></meta>
                <link rel="canonical" href="https://badbelle.ng/about" />
                <meta name="robots" content="index, follow"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Bad Belle About Page" />
                <meta property="og:description" content="This is the Bad Belle About us page. Check here to understand what we do." />
                <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                <meta property="og:url" content="https://badbelle.ng/about" />
                <meta property="og:site_name" content="Bad Belle" />
                <meta name="twitter:title" content="Bad Belle About Page"/>
                <meta name="twitter:description" content="This is the Bad Belle About us page. Check here to understand what we do."/>
                <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                <meta name="twitter:site" content="@badbelleng"/>
                <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
            <div id="header">
                <Header/>
            </div>
            <div>
            <div className={styles.section}>
                    <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>About Bad Belle</h2>
                    <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Bad Belle is a platform where Nigerian movies, music and food can be rated. It is an online aggregator to get the best out of the Nigerian entertainment scene. Our plan is to get our users informed about the best in the categories we select and keep them updated on what is going on around them. We have different rating systems. Read on 'what's the Bellemeter?'</p>
                </div>
                <div id="bellemeter" className={styles.bellemeter}>
                    <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>What's the Bellemeter?</h2>
                    <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>The Bellemeter is a rating system in which we give a score to either a movie, music or food. The scores are gotten from a number of critics in different fields. We work with a number of critics to get the a perfect score to attribute to each category.</p>
                    <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Here are a few of our ratings range for you to consider:</p>
                    <div>
                        <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                            <img src={belleful} alt="belleful"/>
                            Belleful
                        </h3>
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>This means that over 60% of the ratings were positive</p>
                    </div>
                    <div>
                        <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                            <img src={kampe} alt="kampe"/> 
                            Belle Kampe
                        </h3>
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>This means that over 60% of the ratings were neutral</p>
                    </div>
                    <div>
                        <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>
                        <img src={spoil} alt="spoil"/>
                            Belle Spoil
                        </h3>
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>This means that over 60% of the ratings were negative</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );

}

export default About;