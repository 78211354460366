import React,{useEffect,useState} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import {useParams,Link,useLocation} from 'react-router-dom';
import { BASE_URL, GET_RATINGS } from '../../endpoint';
import { Helmet } from 'react-helmet-async';
import axios from "axios";
import style from './css/index.module.css';
import { returnUrl,getBelleRating } from '../../util/functions';
import useGaTracker from '../../useGATracker';
import LoadingSection from '../../components/LoadingSection';
import { useSelector } from 'react-redux';
import theme from './../../css/theme.module.css';
import CSSTransition from "react-transition-group/CSSTransition";
import { TransitionGroup } from "react-transition-group";

const animationTiming = {
    enter: 600,
    exit: 800
};


const Search = () =>{
    const isDay = useSelector(state => state.misc.isDay);
    useGaTracker();
    let {search} = useParams();
    const [isSearching, setisSearching] = useState(false);
    const [isLoaded,setIsLoaded] = useState(false);
    const [autoSearchArray,setAutoSearch] = useState([]);
    let { path } = useLocation();




    useEffect(()=>{
        let autoSearch = async () => {
            setisSearching(true);
            const obj = {search : search}
            const jsonObj = JSON.stringify(obj);
            await axios.post(BASE_URL + GET_RATINGS, jsonObj)
            .then((response) => {
                    setAutoSearch(response.data);
                    setisSearching(false);
                    setIsLoaded(true);
            }).catch((error) =>{
                setisSearching(false);
                setIsLoaded(true);
            })          
        }
        autoSearch();
        
    },[search]);

    return(
        <>
            <LoadingSection isLoading={isSearching} />
            <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
                <div id="header">
            <Helmet>
                    <title>Search for {search} | Bad Belle</title> 
                    <meta name="description" content={"This the search result for " + search}></meta>
                    <link rel="canonical" href={"https://badbelle.ng/" + path} />
                    <meta name="robots" content="index, follow"/>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={search + " | Bad Belle"}/>
                    <meta property="og:description" content={"This the search result for " + search} />
                    <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
                    <meta property="og:url" content={"https://badbelle.ng/" + path} />
                    <meta property="og:site_name" content="Bad Belle" />
                    <meta name="twitter:title" content={search + " | Bad Belle"}/>
                    <meta name="twitter:description" content={"This the search result for " + search}/>
                    <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
                    <meta name="twitter:site" content="@badbelleng"/>
                    <meta name="twitter:creator" content="@badbelleng"/>
                </Helmet>
                    <Header/>
                </div>
                <div id="body" className={style["search-page"]}>
                    {!isSearching && 
                    <>
                        {autoSearchArray.length > 0 ?
                        (<>
                        <CSSTransition 
                        mountOnEnter 
                        unmountOnExit 
                        in={isLoaded} 
                        timeout={animationTiming}
                        classNames={{
                            enter: '',
                            enterActive: theme['FadeIn'],
                            exit: '',
                            exitActive: theme['FadeOut']
                        }}
                        >
                            <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Search Results for : {search}</h2>
                        </CSSTransition>
                        <TransitionGroup>
                        { 
                            autoSearchArray.map((item, i) => {
                            return (
                                <CSSTransition 
                                mountOnEnter 
                                unmountOnExit 
                                in={isLoaded} 
                                timeout={animationTiming}
                                classNames={{
                                    enter: '',
                                    enterActive: theme['FadeIn'],
                                    exit: '',
                                    exitActive: theme['FadeOut']
                                }}
                                key={item.id}>
                                    <Link to={returnUrl(item.category,item.title)} className={`${style['thumbnail']} ${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
                                        <div className={style["img-logo"]}>
                                            <img src={item.url} alt={item.title}/>
                                        </div>
                                        <div className={style['description']}>
                                            <h3 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{item.title}</h3>
                                            <h4 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>{getBelleRating(item.rating)}</h4>
                                        </div>
                                    </Link>
                                </CSSTransition> 
                            )
                            })
                            }
                        </TransitionGroup>
                        </>)
                        :
                        (<>
                        <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>No Search Results for : {search}</h2>
                        <p className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Please try again later.<br/>
                        Thank you!
                        </p>
                    {/*   <button>
                            Yes, please.
                        </button> */}
                        </>)}
                    </>      
                    }
                
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Search;