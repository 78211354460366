import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import './css/index.css';
import imgfounder from '../../img/jimi.jpg';
import david from '../../img/david.jpg';
import victor from '../../img/victor.jpeg';
import { socialMedia } from './components/socialmedia';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';


const Team = () => {

    useGaTracker();

    return(
        <div id="container">
            <Helmet>
                <title>Team | Bad Belle</title>
            </Helmet>
            <div id="header" >
                <Header/>
                <h2>The Team</h2>
                <div className="boss-center">
                    <div id="profile-section">
                        <div id="img-container">
                            <img src={imgfounder} alt="jimi-pic"/>
                        </div>
                        <h2>Jimi Sule</h2>
                        <h3>CEO and Founder</h3>
                        {socialMedia("https://twitter.com/Jimi_sl","https://www.instagram.com/jimi_sl/","https://www.linkedin.com/in/jimisule/")}
                        <p>
                            Jimi is a freelancer who has worked for various startups. He finished from Birmingham City University in 2016, studying Computer Science. He currently runs the parent company of Bad Belle, ImgDoesIt.
                        </p>
                    </div>
                    <div id="profile-section">
                        <div id="img-container">
                            <img src={victor} alt="jimi-pic"/>
                        </div>
                        <h2>Victor Akintunde</h2>
                        <h3>COO & Co-founder</h3>
                        {socialMedia("https://twitter.com/VictorAkin29","https://www.instagram.com/victorakin29/","https://www.linkedin.com/in/victor-akintunde-87651982/")}
                        <p>
                            Victor Akintunde is an internationally trained Actor. A newbie to the Nollywood scene who is diverse within the creative field.  

                            He graduated from the New York Film Academy with a BFA Acting for film. 

                            Victor’s passion for success is driven by his strong belief in the ability to learn from real-life experiences and to embrace the positives of life whilst acknowledging that there are learning opportunities embedded in negative situations.
                        </p>
                    </div>
                    <div id="profile-section">
                        <div id="img-container">
                            <img src={david} alt="jimi-pic"/>
                        </div>
                        <h2>David Everett III</h2>
                        <h3>Business Developer</h3>
                        {socialMedia("https://twitter.com/industries_wolf","https://www.instagram.com/beeowolff/","https://www.linkedin.com/in/david-wolf-everett-58810523/")}
                        <p>
                        David Everett III is a Techie with a knack for being able to sell "stuff". Before joining the BadBelle, he was a lead business developer at a multinational tech company that provides tons of advanced solutions. He studied Computer Science at the University of Portsmouth and also has a masters in Mobile Media Applications from the same University.
                       </p>
                    </div>
                </div>
            </div>
            <div id="body"> 
            </div>
            <Footer/>
        </div>
        
    );
}

export default Team;