import React,{useEffect,useState} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet-async';
import useGaTracker from '../../useGATracker';
import { useSelector, useDispatch } from 'react-redux';
import { weekMusicFunc, latestMusicFunc, topMusicFunc, latestAlbumsFunc, topAlbumsFunc } from '../../store/music-action';
import Section from '../../components/Section';
import DescriptionShell from '../../components/DescriptionShell';
import styles from './css/index.module.css';
import theme from './../../css/theme.module.css';
import LoadingSection from '../../components/LoadingSection';



const Music = () => {
    const dispatch = useDispatch();
    const latestMusic = useSelector(state => state.music.latestMusic);
    const topMusic = useSelector(state => state.music.topMusic);
    const latestAlbums = useSelector(state => state.music.latestAlbums);
    const topAlbums = useSelector(state => state.music.topAlbums);
    const songWeek = useSelector(state => state.music.weekMusic);
    const isDay = useSelector(state => state.misc.isDay);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useGaTracker();

    useEffect(() =>{
        setIsLoading(true);
        dispatch(latestMusicFunc());
        dispatch(topMusicFunc());
        dispatch(latestAlbumsFunc());
        dispatch(topAlbumsFunc());
        dispatch(weekMusicFunc());
    },[dispatch])

    useEffect(()=>{
        if(topMusic.length > 0){
            setIsLoading(false);
            setIsLoaded(true)
        }
    },[topMusic.length])


    return(
        <>
            <LoadingSection isLoading={isLoading} />
            <div id="container" className={`${isDay ? theme['is-day-background'] : theme['is-night-background']}`}>
            <Helmet>
            <title>Music | Bad Belle</title>
            <meta name="description" content="This is the Bad Belle Music page. Check here to see our rating of music."></meta>
            <link rel="canonical" href="https://badbelle.ng/music" />
            <meta name="robots" content="index, follow"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Bad Belle Music Page" />
            <meta property="og:description" content="This is the Bad Belle Music page. Check here to see our rating of music." />
            <meta property="og:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png" />
            <meta property="og:url" content="https://badbelle.ng/music" />
            <meta property="og:site_name" content="Bad Belle" />
            <meta name="twitter:title" content="Bad Belle Music Page"/>
            <meta name="twitter:description" content="This is the Bad Belle Music page. Check here to see our rating of music."/>
            <meta name="twitter:image" content="https://res.cloudinary.com/jimi/image/upload/v1641850831/badbelleimgs/bb-logo_ixkfil.png"/>
            <meta name="twitter:site" content="@badbelleng"/>
            <meta name="twitter:creator" content="@badbelleng"/>
            </Helmet>
            <div id="header">
                <Header/>  
                <div className={styles["music-titles"]}>
                    <h2 className={`${isDay ? theme['is-day-text'] : theme['is-night-text']}`}>Music</h2>
                    <label><HashLink to={'/music#topSongs'}>Top Songs</HashLink></label>
                    <label><HashLink to={'/music#topAlbums'}>Top Albums</HashLink></label>
                    <label><HashLink to={'/music#latestSongs'}>Latest Songs</HashLink></label>
                    <label><HashLink to={'/music#latestAlbums'}>Latest Albums</HashLink></label>
                </div>
                <Section title="Top Songs" id="topSongs" data={topMusic} isSubmenu={true} isLoading={isLoaded}/>
                <Section title="Top Albums" id="topAlbums" data={topAlbums} isSubmenu={true} isLoading={isLoaded}/>
                <DescriptionShell title="Song of the Week" data={songWeek} index={0} isLoading={isLoaded}/>
                <Section title="Latest Songs" id="latestSongs" data={latestMusic} isSubmenu={true} isLoading={isLoaded}/>
                <Section title="Latest Albums" id="latestAlbums" data={latestAlbums} isSubmenu={true} isLoading={isLoaded}/>
            </div>
            <div id="body"> 
            </div>
            <Footer/>
            </div>
        </>
    );

}

export default Music