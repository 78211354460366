import React from 'react';
import twitter from './../img/twitter.png';
import instagram from './../img/instagram.png';
import youtube from './../img/youtube.png';


const SocialMedia = ()=>{

    return(
            <>
                <a target="_blank" href="https://twitter.com/badbelleng" rel="noreferrer">
                    <img src={twitter} alt="twitter" />
                </a>
                <a target="_blank" href="https://www.instagram.com/badbelleng/" rel="noreferrer">
                    <img src={instagram} alt="instagram" />
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCpi171zYOdv3N1o0Nm94VAQ?guided_help_flow=3"  rel="noreferrer">
                    <img src={youtube} alt="youtube" />
                </a>
            </>
    );

}

export default SocialMedia;